/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 261px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 74.09638554216868%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAUl1whM6P//EABkQAQADAQEAAAAAAAAAAAAAAAIBAxESAP/aAAgBAQABBQKywiIsl3LvVG+A4Z3P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgEEAwAAAAAAAAAAAAAAAREAAhAhMRITkf/aAAgBAQAGPwIOlwdZPFamaPLJp6Mzuf/EABoQAAMBAQEBAAAAAAAAAAAAAAABESFRcbH/2gAIAQEAAT8hwAfBQU4p4imihiykq1Krpw/B/9oADAMBAAIAAwAAABDf3//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EEf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMUFRYf/aAAgBAQABPxBCbePYJkqZIlZ595kawEvL+4krHTh9Lh7PlOJjhu9Jg5//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"dalmation\"\n        title=\"dalmation\"\n        src=\"/static/1a4690f9c08cda8ac95c5b4931080339/cb50e/dalmation.jpg\"\n        srcset=\"/static/1a4690f9c08cda8ac95c5b4931080339/4d5fb/dalmation.jpg 166w,\n/static/1a4690f9c08cda8ac95c5b4931080339/cb50e/dalmation.jpg 261w\"\n        sizes=\"(max-width: 261px) 100vw, 261px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Dalmation at rest"), "\n", React.createElement(_components.p, null, "The dalmation is a widely recognized dog whose breed has been traced back to Dalmatia in the Republic of Croatia. Its spotted appearance has been associated with Disney’s 101 Dalmations and ", React.createElement(_components.a, {
    href: "/budweiser-king-bohemian-beers/",
    title: "Budweiser, the King of Bohemian Beers"
  }, "Budweiser"), " but perhaps is best known as the “firehouse dog”. Originally they found a place in American firehouses because they were intelligent and easily trained and could lead the horse-drawn fire wagon through the streets to the emergency. The dogs would clear a path for the wagon or would help to move the horses along as they pulled the fire wagon. In addition to their wagon duties the dogs have an excellent guardian instinct and would also guard the firehouse and equipment while the fire fighters were away at an emergency."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
